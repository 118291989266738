import '@fortawesome/fontawesome-svg-core/styles.css';

const config = require('@fortawesome/fontawesome-svg-core');

config.autoAddCss = false;

export * as global from './src/styles/global.scss';
import './src/styles/DOC/prism.css';
//export const registerServiceWorker = () => true;
//export const onServiceWorkerUpdateReady= () => window.location.reload(true);

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);
  window.scrollTo(...(currentPosition || [0, 0]));
  return false;
};
