exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-event-guide-js": () => import("./../../../src/templates/event-guide.js" /* webpackChunkName: "component---src-templates-event-guide-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-internal-page-js": () => import("./../../../src/templates/internal-page.js" /* webpackChunkName: "component---src-templates-internal-page-js" */),
  "component---src-templates-news-guide-js": () => import("./../../../src/templates/news-guide.js" /* webpackChunkName: "component---src-templates-news-guide-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-search-results-js": () => import("./../../../src/templates/search-results.js" /* webpackChunkName: "component---src-templates-search-results-js" */),
  "slice---src-components-layout-layout-js": () => import("./../../../src/components/layout/Layout.js" /* webpackChunkName: "slice---src-components-layout-layout-js" */)
}

